<template>
  <div v-if="!isLoading">
    <teleport to="body">
      <job-person-spec-modal
        @closeModal="toggleJobSpecModal(false)"
        v-if="jobSpecModalOpen"
        :jobSpecification="getHiringRequest.job_specification"
        :personSpecification="getHiringRequest.person_specification"
      />
      <base-confirmation-dialog
        :show="confirmationDialog"
        title="Are you sure?"
      >
        <base-button
          v-if="!declineOfferCheck"
          @click="confirmOffer"
          :animateButton="animateButton"
        >
          Confirm
        </base-button>
        <base-button
          v-else
          @click="declineOffer"
          :animateButton="animateButton"
        >
          Decline Offer
        </base-button>
        <base-button
          v-show="!animateButton"
          mode="cancel"
          @click="closeConfirmationDialog"
          >Cancel</base-button
        >
      </base-confirmation-dialog>
      <edit-hiring-request
        v-if="editDialog"
        :getHiringRequestData="getHiringRequest"
        :animateButton="animateButton"
        @close-modal="editRequest"
        @submit-modal="submitHiringRequestEdit"
      />
    </teleport>
    <transition appear>
      <div class="flex flex-row gap-12 p-4">
        <div class="w-1/2 min-w-sm mx-auto my-5 text-darkGrey">
          <staff-request-form
            :showOfferButtons="false"
            :showEditButton="false"
            @confirm-offer="confirmDialog"
            @cancel-offer="confirmDialogCancel"
            @edit-request="editRequest"
            :data="getHiringRequest"
            :animateButton="animateButton"
            @toggleJobSpecModal="toggleJobSpecModal(true)"
          />
        </div>
        <div class="w-1/2 min-w-sm mx-auto my-5 text-darkGrey">
          <staff-request-form
            :showOfferButtons="true"
            :showEditButton="false"
            :title="'New Offer'"
            @confirm-offer="confirmDialog"
            @cancel-offer="confirmDialogCancel"
            @edit-request="editRequest"
            :data="getHiringRequest"
            :animateButton="animateButton"
            @toggleJobSpecModal="toggleJobSpecModal(true)"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
import staffRequestForm from "@/components/ui/baseComponents/Recruitment/staffRequestForm.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
import editHiringRequest from "@/components/modal/editHiringRequest.vue";
export default {
  components: {
    staffRequestForm,
    jobPersonSpecModal,
    baseConfirmationDialog,
    editHiringRequest,
  },
  async created() {
    this.isLoading = true;
    await this.fetchHiringRequest(this.$route.params.manageVacancy);
    this.isLoading = false;
  },
  data() {
    return {
      jobSpecModalOpen: false,
      isLoading: false,
      animateButton: false,
      confirmationDialog: false,
      declineOfferCheck: false,
      offerID: null,
      editDialog: false,
      offerPayload: {
        practice: null,
        hiring_request: null,
        user: null,
        work_pattern: null,
        amount: null,
        joining_date: null,
      },
      offerDetails: {},
    };
  },
  methods: {
    confirmDialog(data) {
      this.offerDetails = data;
      this.confirmationDialog = true;
    },
    confirmDialogCancel(data) {
      this.declineOfferCheck = true;
      this.offerID = data;
      this.confirmationDialog = true;
    },
    editRequest() {
      this.editDialog = !this.editDialog;
    },
    async confirmOffer() {
      this.animateButton = true;
      this.offerPayload.amount = this.offerDetails.amount;
      this.offerPayload.joining_date = this.offerDetails.joining_date;
      this.offerPayload.practice = this.getHiringRequest.practice_id;
      this.offerPayload.hiring_request = this.getHiringRequest.id;
      this.offerPayload.user = Number(this.$route.params.userId);
      this.offerPayload.work_pattern =
        this.getHiringRequest.work_patterns[0].id;
      try {
        await this.$store.dispatch("REnewHire/createOffer", this.offerPayload);
        this.$router.back();
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.back();
      }
    },
    async declineOffer() {
      console.log("DECLINE");
      this.animateButton = true;
      this.offerPayload.offer = Number(this.offerID);
      this.offerPayload.status = 0;
      this.offerPayload.amount = this.getHiringRequest.starting_salary;
      try {
        await this.$store.dispatch("REnewHire/updateOffer", this.offerPayload);
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "false" },
        });
      }
    },
    async submitHiringRequestEdit(data) {
      this.animateButton = true;
      console.log(data);
      // this.offerPayload.practice = this.getHiringRequest.practice_id;
      // this.offerPayload.hiring_request = this.getHiringRequest.id;
      // this.offerPayload.user = Number(this.$route.params.userId);
      // this.offerPayload.work_pattern =
      //   this.getHiringRequest.work_patterns[0].id;
      // this.offerPayload.amount = this.getHiringRequest.starting_salary;
      try {
        await this.$store.dispatch("REnewHire/updateHiringRequest", data);
        this.animateButton = false;
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "true" },
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
        this.$router.push({
          name: "re-manage-vacancy",
          params: { manageVacancy: this.$route.params.manageVacancy },
          query: { success: "false" },
        });
      }
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
    async fetchHiringRequest(requestFormId) {
      try {
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },
  },
};
</script>
